<template>
    <div>
        <div v-if="!currentContract">
            <DxLoadIndicator
                    id="medium-indicator-curren-contract"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <ContractInfo v-if="currentContract && currentContractKind == 'ContractInfo'" :currentContract="currentContract" :showStatus="getContract != null" />
        <CarrierContractInfo v-if="currentContract && currentContractKind == 'CarrierContractInfo'" :currentContract="currentContract" :showStatus="getContract != null" />
        <div v-if="currentContract && currentContractId == contractId" class="mt-3">
            <ul class="nav nav-tabs" :id="'contractTab-'+contractId" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" @click="getContractStatusHistorys(contractId)" :id="'status-historys-tab-'+contractId" data-toggle="tab" :href="'#status-historys-'+contractId" role="tab" :aria-controls="'status-historys-'+contractId" aria-selected="true">История статусов</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" @click="getContractFiles(contractId)" :id="'files-tab-'+contractId" data-toggle="tab" :href="'#files-'+contractId" role="tab" :aria-controls="'files-'+contractId" aria-selected="false">Файлы ({{ currentContract.files }})</a>
                </li>
            </ul>
            <div class="tab-content" :id="'contractTabContent-'+contractId">
                <div class="tab-pane fade show active" :id="'status-historys-'+contractId" role="tabpanel" :aria-labelledby="'status-historys-tab-'+contractId">
                    <DxDataGrid
                            id="gridContainerStatusHistorys"
                            :data-source="currentContractStatusHistorys"
                            :columns="columnsStatusHistorys"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsStatusHistorys"
                    ></DxDataGrid>
                </div>
                <div class="tab-pane fade" :id="'files-'+contractId" role="tabpanel" :aria-labelledby="'files-tab-'+contractId">
                    <DxDataGrid
                            id="currentContractFiles"
                            key-expr="fileId"
                            :data-source="currentContractFiles"
                            :columns="columnsFiles"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsFiles"
                    >
                        <template #file-name-template="{ data }">
                            <button :id="'btn-download-file-'+data.data.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(currentContract.contractId, data.data.fileId, data.data.fileName)">{{ data.data.fileName }}</button>
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../shared/services';
    import localStore from './../store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import { DxDataGrid } from 'devextreme-vue/data-grid';
    import ContractInfo from './ContractInfo';
    import CarrierContractInfo from './CarrierContractInfo';

    export default {
        name: "ContractDetail",
        props: ['contractId', 'Agent', 'getContract'],
        components: {
            DxLoadIndicator,
            DxDataGrid,
            ContractInfo,
            CarrierContractInfo
        },
        data() {
            return {
                currentContractId: '',
                currentContractKind: '',
                currentContract: null,
                currentContractStatusHistorys: null,
                columnsStatusHistorys: [
                    {
                        caption: 'Дата',
                        dataField: 'changeTime',
                        dataType: 'datetime',
                        format: 'dd.MM.yyyy HH:mm',
                        allowSorting: false,
                        width: 130,
                        allowResizing: false
                    }, {
                        caption: 'Примечание',
                        dataField: 'note',
                        allowSorting: false,
                        columnResizingMode: 'widget',
                    }, {
                        caption: 'Статус',
                        dataField: 'status',
                        allowSorting: false,
                        encodeHtml: false,
                        customizeText: this.statusCustomizeText,
                        width: 130,
                        allowHiding: false
                    }
                ],
                currentContractFiles: null,
                columnsFiles: [
                    {
                        caption: 'Имя',
                        dataField: 'fileName',
                        allowSorting: false,
                        encodeHtml: false,
                        cellTemplate: "file-name-template",
                        allowHiding: false,
                    }, {
                        caption: 'Тип файла',
                        dataField: 'fileKind',
                        allowSorting: false,
                        width: 150
                    }, {
                        caption: 'Примечание',
                        dataField: 'note',
                        allowSorting: false,
                        width: 500
                    }
                ],
            }
        },
        mounted() {
            this.loadContract();
        },
        methods: {
            getContractStatusName(status) {
                let statuses = {
                    'csNone': '<span class="badge badge-dark font-12">None</span>',
                    'csProcessing': '<span class="badge badge-warning font-12">В обработке</span>',
                    'csSigned': '<span class="badge badge-success font-12">Активен</span>',
                    'csSuspended': '<span class="badge badge-danger font-12">Приостановлен</span>',
                    'csSuppliment': '<span class="badge badge-warning font-12">Доп. соглашение</span>',
                    'csTerminated': '<span class="badge badge-danger font-12">Расторгнут</span>',
                };

                return statuses[status];
            },
            loadContract() {
                if(this.currentContractId != this.contractId) {
                    this.currentContract = null;
                    this.currentContract = null;
                    this.currentContractStatusHistorys = null;
                    this.currentContractFiles = null;
                    this.currentContractId = this.contractId;

                    localStore.dispatch('getContract', {tokenId: this.Agent.tokenId, contractId: this.contractId}).then(() => {
                        this.currentContract = localStore.state.contract;

                        if(this.getContract) {
                            this.getContract(this.currentContract);
                        }

                        switch (this.currentContract.contractKind) {
                            case 'ДП-АВТО':
                            case 'ДЗ-АВТО':
                                this.currentContractKind = 'CarrierContractInfo';
                                break;
                            default:
                                this.currentContractKind = 'ContractInfo';
                                break;
                        }

                        this.getContractStatusHistorys(this.contractId);
                    }).catch(this.handleError);
                }
            },
            getContractStatusHistorys(contractId) {
                this.currentContractStatusHistorys = null;
                localStore.dispatch('getContractStatusHistorys', {tokenId: this.Agent.tokenId, contractId: contractId}).then(() => {
                    this.currentContractStatusHistorys = localStore.state.contractStatusHistorys;
                })
            },
            statusCustomizeText(cellInfo) {
                return this.getContractStatusName(cellInfo.value);
            },
            getContractFiles(contractId) {
                this.currentContractFiles = null;

                localStore.dispatch('getContractFiles', {tokenId: this.Agent.tokenId, contractId: contractId}).then(() => {
                    this.currentContractFiles = localStore.state.contractFiles;
                })
            },
            downloadFile(contractId, fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        let fileExt = fileName.split('.').pop();

                        const linkSource = `data:application/${fileExt};base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            customizeColumnsStatusHistorys(columns) {
                if(columns.length > 0) {
                    if (this.$mq == 'sm' || this.$mq == 'md') {
                        columns[1].hidingPriority = 0;
                    } else {
                        columns[1].hidingPriority = null;
                    }
                }
            },
            customizeColumnsFiles(columns) {
                if(columns.length > 0) {
                    if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                        columns[1].hidingPriority = 0;
                        columns[2].hidingPriority = 1;
                    } else {
                        columns[1].hidingPriority = null;
                        columns[2].hidingPriority = null;
                    }
                }
            },
            handleError: function (error) {
                console.error(error);
                if(error.response.status == 404) {
                    this.$router.push({name: 'Error404'});
                }
            },
        }
    }
</script>

<style scoped>

</style>
