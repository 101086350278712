<template>
    <div class="row current-contract">
        <div v-if="!showStatus" class="col-12 col-md-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Номер договора:
            </p>
            <p class="item-text">
                {{ currentContract.number }}
            </p>
        </div>
        <div v-if="!showStatus" class="col-12 col-md-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Дата договора:
            </p>
            <p class="item-text">
                {{ currentContract.сontractDate | moment("DD.MM.YYYY") }}
            </p>
        </div>
        <div v-if="!showStatus" class="col-12 col-md-6 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Подписант:
            </p>
            <p class="item-text">
                {{ currentContract.signatoryName }}
            </p>
        </div>
        <div v-if="!showStatus" class="col-12 col-md-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Валюта:
            </p>
            <p class="item-text">
                {{ currentContract.currencyName }}
            </p>
        </div>
        <div v-if="showStatus" class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Номер договора:
            </p>
            <p class="item-text">
                {{ currentContract.number }}
            </p>
        </div>
        <div v-if="showStatus" class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Дата договора:
            </p>
            <p class="item-text">
                {{ currentContract.сontractDate | moment("DD.MM.YYYY") }}
            </p>
        </div>
        <div v-if="showStatus" class="col-12 col-md-6 col-lg-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Подписант:
            </p>
            <p class="item-text">
                {{ currentContract.signatoryName }}
            </p>
        </div>
        <div v-if="showStatus" class="col-12 col-md-6 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Валюта:
            </p>
            <p class="item-text">
                {{ currentContract.currencyName }}
            </p>
        </div>
        <div v-if="showStatus" class="col-12 col-md-6 col-lg-2 mb-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2 mb-0">
                Состояние:
            </p>
            <p class="item-text" v-html="getContractStatusName(currentContract.status)"></p>
        </div>
        <div class="col-12 col-md-6">
            <p>
                Условия оплаты:
            </p>
            <p class="item-text">
                {{ currentContract.paymentTermCaption }}
            </p>
        </div>
        <div class="col-12 col-md-6">
            <p>
                Условия окончания:
            </p>
            <p class="item-text">
                {{ getLimitName(currentContract.limit) }}
            </p>
            <p class="item-text" v-if="currentContract.limit == 'cpkDate' || currentContract.limit == 'cpkDateAndSummary'">
                Дата: {{ currentContract.limitDate | moment("DD.MM.YYYY") }}
            </p>
            <p class="item-text" v-if="currentContract.limit == 'cpkSummary' || currentContract.limit == 'cpkDateAndSummary'">
                Сумма: {{ currentContract.limitSummary | formatMoney }}
            </p>
        </div>
        <div class="col-12 mt-3">
            <p>
                Нормы простоя
            </p>
            <hr>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку по РК:
            </p>
            <p class="item-text">
                {{ currentContract.timingLoadingKZ }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку по СНГ:
            </p>
            <p class="item-text">
                {{ currentContract.timingLoadingUIS }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку прочие:
            </p>
            <p class="item-text">
                {{ currentContract.timingLoadingOther }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку по РК:
            </p>
            <p class="item-text">
                {{ currentContract.timingUnloadingKZ }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку по СНГ:
            </p>
            <p class="item-text">
                {{ currentContract.timingUnloadingUIS }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку прочие:
            </p>
            <p class="item-text">
                {{ currentContract.timingUnloadingOther }} ч.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CarrierContractInfo",
        props: ['currentContract', 'showStatus'],
        methods: {
            getLimitName(limit) {
                let limits = {
                    'cpkNotLimited': 'Пролонгируемый',
                    'cpkSimple': 'Разовый',
                    'cpkDate': 'Органичен датой',
                    'cpkSummary': 'Органичен суммой',
                    'cpkDateAndSummary': 'Органичен датой и суммой',
                    'cpkMasterContract': 'Органичен мастер договором'
                };

                return limits[limit];
            },
            getContractStatusName(status) {
                let statuses = {
                    'csNone': '<span class="badge badge-dark font-12">None</span>',
                    'csProcessing': '<span class="badge badge-warning font-12">В обработке</span>',
                    'csSigned': '<span class="badge badge-success font-12">Активен</span>',
                    'csSuspended': '<span class="badge badge-danger font-12">Приостановлен</span>',
                    'csSuppliment': '<span class="badge badge-warning font-12">Доп. соглашение</span>',
                    'csTerminated': '<span class="badge badge-danger font-12">Расторгнут</span>',
                };

                return statuses[status];
            },
        }
    }
</script>

<style lang="scss" scoped>
    .current-contract {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
